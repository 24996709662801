import React from 'react';
import { useSearchParams } from "react-router-dom";
import { TextField } from '@mui/material';

import { KaaveeApi } from '../api/KaaveeApi';
import { DealHistoryChart } from '../components/HistoryChart';
import { PriceActionTable } from '../components/PriceActionTable';

const Deal: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [items, _setItems] = React.useState<KaaveeApi.DealHistory[]>([])
    const [error, _setError] = React.useState(false)

    const setResult = (items: KaaveeApi.DealHistory[]) => {
        const error = items == null
        _setError(error)
        if (!error)
            _setItems(items)
    }

    const queryParams = React.useMemo(() => {
        return searchParams.get("id") ?? ""
    }, [searchParams])

    const getHistory = React.useCallback(() => {
        const regex: RegExp = new RegExp('\\d{6,}')
        const kvId = queryParams?.match(regex)?.at(0)

        if (kvId == null) {
            setResult([])
            return
        }

        fetch(`${process.env.REACT_APP_BASE_URL}/deal/${kvId}`)
            .then(response => {
                if (!response.ok)
                    throw response
                return response.json()
            })
            .then(json => setResult([json]))
            .catch(error => {
                console.error(error)
                setResult([])
            })
    }, [queryParams])

    React.useEffect(() => {
        getHistory()
    }, [getHistory])

    const onChange = function (event: React.ChangeEvent<HTMLInputElement>) {
        setSearchParams({
            id: event.target.value
        }, { replace: true })
    }

    const onSearch = function (event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter') {
            getHistory()
        }
    }

    return (
        <div className='DealPage'>
            <TextField fullWidth value={queryParams} label="URL" variant="outlined" onChange={onChange} onKeyDown={onSearch} error={error} />
            <PriceActionTable deals={items} disableColumnMenu={true} hideFooter={true} />
            <DealHistoryChart history={items} />
        </div>
    );
}

export default Deal
