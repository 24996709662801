import { Button } from '@mui/material';
import React from 'react';

const Home: React.FC = () => {
    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Button variant="contained" href="/deal">deals</Button>&nbsp;
            <Button variant="contained" href="/price-action">price action</Button>&nbsp;
            <Button variant="contained" href="/stats">stats</Button>
        </div>
    );
}

export default Home
