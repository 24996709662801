import { Grid, Paper } from '@mui/material'

const container = {
    display: 'flex',
    alignItems: 'center',
    p: 1,
}

interface DealListProps {
    children: React.ReactNode[]
}

export default function DealList(props: DealListProps) {
    return (
        <Grid container direction="row" spacing={1} sx={{ my: 1 }}>
            {props.children.map(child =>
                <Grid item>
                    <Paper sx={container}>
                        {child}
                    </Paper>
                </Grid>
            )}
        </Grid>
    );
}
