import './App.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import 'chartjs-adapter-moment'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Link, Route, Routes } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import PriceAction from './pages/PriceAction';
import Stats from './pages/Stats';
import Deal from './pages/Deal';
import Home from './pages/Home';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const theme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className="App">
                    <Routes>
                        <Route path="/deal" element={<Deal />} />
                        <Route path="/price-action" element={<PriceAction />} />
                        <Route path="/stats" element={<Stats />} />
                        <Route path="/" element={<Home />} />
                    </Routes>
                    <Link to="/deal"/>
                </div>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
