import React from 'react'
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridSelectionModel, GridValueFormatterParams } from '@mui/x-data-grid';

import { KaaveeApi } from '../api/KaaveeApi'
import { Formatters } from '../Formatters';

const currencyFormatter = function (params: GridValueFormatterParams): string {
    return Formatters.Currency.format(params.value)
}

const percentageFormatter = function (params: GridValueFormatterParams): string {
    return (params.value * 100).toFixed(2) + "%"
}

const columns: GridColDef[] = [
    {
        field: 'kvId', headerName: 'ID', flex: 1,
        renderCell: (params: GridRenderCellParams<any, any, any>) => {
            const id = params.row.kvId
            return (<a href={`${process.env.REACT_APP_KV_URL}/${id}`} target="_blank" rel="noopener noreferrer">{id}</a>)
        }
    },
    { field: 'title', headerName: 'Title', flex: 3 },
    { field: 'priceInfo.first', valueGetter: ((params) => params.row.priceInfo.first), headerName: 'Start price', flex: 1, type: 'number', valueFormatter: currencyFormatter },
    { field: 'priceInfo.last', valueGetter: ((params) => params.row.priceInfo.last), headerName: 'Current Price', flex: 1, type: 'number', valueFormatter: currencyFormatter },
    { field: 'priceInfo.abs', valueGetter: ((params) => params.row.priceInfo.abs), headerName: 'Diff', flex: 1, type: 'number', valueFormatter: currencyFormatter },
    { field: 'priceInfo.rel', valueGetter: ((params) => params.row.priceInfo.rel), headerName: 'Diff (%)', flex: 1, type: 'number', valueFormatter: percentageFormatter },
];

interface IPriceActionTableProps {
    deals: KaaveeApi.DealPriceAction[]
    onSelectionModelChange?: (ids: GridSelectionModel) => void
    onCellDoubleClick?: (params: GridCellParams) => void
    hideFooter?: boolean
    disableColumnMenu?: boolean
    disableSelectionOnClick?: boolean
}

export const PriceActionTable: React.FC<IPriceActionTableProps> = ({
    deals,
    onSelectionModelChange,
    onCellDoubleClick,
    hideFooter,
    disableColumnMenu,
    disableSelectionOnClick
}) => {
    const [pageSize, setPageSize] = React.useState<number>(5)
    return (
        <div className="PriceActionTable">
            <div style={{ flexGrow: 1 }}>
                <DataGrid
                    rows={deals}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    rowsPerPageOptions={[5,10,20]}
                    columns={columns}
                    autoHeight={true}
                    disableColumnMenu={disableColumnMenu}
                    hideFooter={hideFooter}
                    getRowId={(deal: KaaveeApi.DealPriceAction) => deal.kvId}
                    onSelectionModelChange={onSelectionModelChange}
                    onCellDoubleClick={onCellDoubleClick}
                />
            </div>
        </div>
    )
}
