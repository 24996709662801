import moment from 'moment';
import React from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Checkbox, FormControlLabel, FormGroup, SelectChangeEvent, TextField } from '@mui/material';
import { green, red } from '@mui/material/colors'
import ListItemText from '@mui/material/ListItemText'
import { GridCellParams, GridSelectionModel } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';

import { KaaveeApi } from '../api/KaaveeApi';
import { DealTypeSelect } from '../components/DealTypeSelect';
import { DealHistoryChart } from '../components/HistoryChart';
import { PriceActionTable } from '../components/PriceActionTable';
import DealList from '../components/DealList';

interface PriceActionQueryParams {
    since: string,
    type: KaaveeApi.DealType,
    inactive: boolean
}

function getSummaries(deals: KaaveeApi.DealPriceAction[]): {
    raisers: number,
    droppers: number,
} {
    var raisers = 0
    var droppers = 0
    deals.forEach((deal) => {
        if (deal.priceInfo.abs > 0)
            raisers++
        else if (deal.priceInfo.abs < 0)
            droppers++
    })
    return {
        droppers: droppers,
        raisers: raisers,
    }
}

const PriceAction: React.FC = () => {
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()

    const [filteredItems, _setFilteredItems] = React.useState<KaaveeApi.DealPriceAction[]>([])
    const [error, _setError] = React.useState(false)

    const [queryItems, _setQueryItems] = React.useState<KaaveeApi.DealPriceAction[]>([])

    const summaries = React.useMemo(() => {
        return getSummaries(filteredItems)
    }, [filteredItems])

    React.useEffect(() => {
        const error = queryItems == null
        _setError(error)
        if (error) {
            _setFilteredItems([])
            return
        }

        const query = searchParams.get("filter") ?? ""
        if (query.length !== 0)
            _setFilteredItems(queryItems.filter((e) => e.title.toLowerCase().includes(query.toLowerCase())))
        else
            _setFilteredItems(queryItems)
    }, [queryItems, searchParams])

    const queryParams: PriceActionQueryParams = React.useMemo(() => {
        const since = searchParams.get("since") ?? (() => {
            const now = new Date();
            const backdate = new Date(now.setDate(now.getDate() - 14));
            return moment(backdate).format("YYYY-MM-DD")
        })()

        const typeParam = searchParams.get("type")
        let type: KaaveeApi.DealType
        if (typeParam && typeParam in KaaveeApi.DealType)
            type = KaaveeApi.DealType[typeParam as keyof typeof KaaveeApi.DealType]
        else
            type = KaaveeApi.DealType.SALE_APARTMENT

        const inactive = searchParams.get("inactive") == "true"
        return {
            since: since,
            type: type,
            inactive: inactive
        }
    }, [searchParams])

    React.useEffect(() => {
        if (queryParams.since == null || queryParams.type == null || queryParams.inactive == null) {
            _setQueryItems([])
            return
        }

        fetch(`${process.env.REACT_APP_BASE_URL}/price-action?type=${queryParams.type}&since=${queryParams.since}&inactive=${queryParams.inactive}`)
            .then(response => {
                if (!response.ok) {
                    throw response
                }
                return response.json()
            })
            .then(json => _setQueryItems(json))
            .catch(error => {
                console.error(error)
                _setQueryItems([])
            })
    }, [queryParams.since, queryParams.type, queryParams.inactive, _setQueryItems])

    const onSinceChange = React.useCallback((value: Date | null) => {
        if (value == null)
            return
        searchParams.set("since", moment(value).format("YYYY-MM-DD"))
        setSearchParams(searchParams)
    }, [searchParams, setSearchParams])

    const onTypeChange = React.useCallback((event: SelectChangeEvent<KaaveeApi.DealType>) => {
        const type = KaaveeApi.DealType[event.target.value as keyof typeof KaaveeApi.DealType]
        if (type === undefined)
            return

        searchParams.set("type", type.toString())
        setSearchParams(searchParams)
    }, [searchParams, setSearchParams])

    const onFilterChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const filter = event.target.value
        if (filter.length === 0)
            searchParams.delete("filter")
        else
            searchParams.set("filter", filter)
        setSearchParams(searchParams, { replace: true })
    }, [searchParams, setSearchParams])

    const onActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked)
            searchParams.set("inactive", "true")
        else
            searchParams.delete("inactive")
        setSearchParams(searchParams, { replace: true })
      };

    const [selectedId, setSelectedId] = React.useState<number | null>(null)
    const [dealHistory, setDealHistory] = React.useState<KaaveeApi.DealHistory[]>([])

    React.useEffect(() => {
        if (selectedId == null)
            return

        fetch(`${process.env.REACT_APP_BASE_URL}/deal/${selectedId}`)
            .then(response => {
                if (!response.ok)
                    throw response
                return response.json()
            })
            .then(json => setDealHistory([json]))
            .catch(error => {
                console.error(error)
            })
    }, [selectedId])

    const onSelectionModelChange = function (ids: GridSelectionModel) {
        if (ids.length <= 0) {
            setSelectedId(null)
            return
        }
        const id = parseInt(ids[0].toString())
        setSelectedId(id)
    }

    const onCellDoubleClick = function (params: GridCellParams) {
        navigate(
            {
                pathname: "/deal",
                search: `?id=${params.row.kvId}`
            }
        )
    }

    const dropper = () => {
        return (<>
            <ArrowDownwardIcon sx={{ color: red[500] }} />
            <ListItemText secondary='Price drops' primary={summaries.droppers} />
        </>)
    }

    const raiser = () => {
        return (<>
            <ArrowUpwardIcon sx={{ color: green[500] }} />
            <ListItemText secondary='Price raises' primary={summaries.raisers} />
        </>)
    }

    return (
        <div className='PriceActionPage'>
            <DatePicker inputFormat="DD.MM.YYYY" className='DatePicker' label="Since" onChange={onSinceChange} value={queryParams.since} renderInput={(params) => <TextField {...params} sx={{ my: 1 }} error={error} />} />
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={queryParams.inactive} onChange={onActiveChange} />} label="Show inactive deals" />
            </FormGroup>
            <DealTypeSelect value={queryParams.type} onChange={onTypeChange} />
            <TextField sx={{ my: 1 }} fullWidth value={searchParams.get("filter") ?? ""} label="Filter" variant="outlined" onChange={onFilterChange} error={error} />
            <DealList children={[dropper(), raiser()]} />
            <PriceActionTable deals={filteredItems} onSelectionModelChange={onSelectionModelChange} onCellDoubleClick={onCellDoubleClick} />
            {dealHistory.length > 0 && <DealHistoryChart history={dealHistory} />}
        </div>
    );
}

export default PriceAction
