export module KaaveeApi {
    export enum DealType {
        SALE_APARTMENT = "SALE_APARTMENT",
        RENT_APARTMENT = "RENT_APARTMENT",
        SALE_HOUSE = "SALE_HOUSE",
        RENT_HOUSE = "RENT_HOUSE",
        SALE_TERRACED_HOUSE = "SALE_TERRACED_HOUSE",
        RENT_TERRACED_HOUSE = "RENT_TERRACED_HOUSE",
        SALE_LAND = "SALE_LAND",
        RENT_LAND = "RENT_LAND",
        RENT_BUSINESS = "RENT_BUSINESS",
        SALE_BUSINESS = "SALE_BUSINESS",
    }

    export interface PriceInfo {
        first: number
        last: number
        abs: number
        rel: number
    }

    export interface DealSummary {
        date: number
        count: number
        price: number
        squares: number
        averagePrice: number
        averageSquarePrice: number
    }

    export interface DealSummaryHistory {
        type: string
        district: string
        averageSquarePrice: PriceInfo
        history: DealSummary[]
    }

    export interface DealHistoryEntry {
        date: number
        price: number
        status: string
    }

    export interface DealHistory {
        kvId: number
        date: number
        title: string
        price: number
        type: string
        status: string
        rooms: number
        squares: number
        priceInfo: PriceInfo

        history?: DealHistoryEntry[]
    }

    export interface DealPriceAction {
        kvId: number
        date: number
        title: string
        price: number
        type: string
        status: string
        rooms: number
        squares: number
        priceInfo: PriceInfo
    }
}
