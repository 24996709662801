import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { KaaveeApi } from '../api/KaaveeApi';

const keys = Object.keys(KaaveeApi.DealType)

export function DealTypeSelect(
    {
        value, onChange
    }: { value: KaaveeApi.DealType, onChange: (event: SelectChangeEvent<KaaveeApi.DealType>) => void }
) {
    return (
        <FormControl fullWidth sx={{ my: 1 }} >
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
                value={value}
                label="Type"
                onChange={onChange}
            >
                {keys.map((m) => <MenuItem key={m} value={m}>{m}</MenuItem>)}
            </Select>
        </FormControl>
    )
}
