import {
    ChartData,
    ChartOptions,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { KaaveeApi } from '../api/KaaveeApi'
import { Formatters } from '../Formatters'
import { ColorPalette } from './ColorPalette'
import React from 'react'

interface DealChartData {
    x: Date
    y: number
}

const dealHistoryOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: true,
            text: 'Price history',
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                tooltipFormat: 'DD.MM.YYYY'
            },
            title: {
                display: true,
                text: 'Date'
            }
        },
        y: {
            ticks: {
                callback: function (label) {
                    return Formatters.Currency.format(label as number)
                }
            },
        }
    }
};

function dealHistoryToData(deals: KaaveeApi.DealHistory[]): ChartData<"line", DealChartData[]> {
    return {
        datasets: deals.map(function (deal: KaaveeApi.DealHistory, index) {
            const color = ColorPalette[index]
            return {
                label: deal.title,
                data: deal.history?.map((entry: KaaveeApi.DealHistoryEntry) => ({
                    x: new Date(entry.date),
                    y: entry.price
                })) ?? [],
                borderColor: color,
                backgroundColor: color,
            }
        })
    }
}

export const DealHistoryChart: React.FC<{
    history: KaaveeApi.DealHistory[]
}> = ({
    history
}) => {
        const [data, setData] = React.useState<ChartData<"line", DealChartData[]> | null>(null)

        React.useEffect(() => {
            setData(dealHistoryToData(history))
        }, [history])
        return (
            <div className="HistoryChart DealHistoryChart">
                {data && <Line options={dealHistoryOptions} data={data} />}
            </div>
        )
    }

const priceHistoryOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: true,
            text: 'Price history',
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                tooltipFormat: 'DD.MM.YYYY'
            },
            title: {
                display: true,
                text: 'Date'
            }
        },
        y: {
            ticks: {
                callback: function (label) {
                    return Formatters.Currency.format(label as number)
                }
            },
        },
    }
}

function dealSummaryHistoryToPriceData(dealSummary: KaaveeApi.DealSummaryHistory[]): ChartData<"line", DealChartData[]> {
    return {
        datasets: dealSummary.map(function (dealSummary: KaaveeApi.DealSummaryHistory, index) {
            const color = ColorPalette[index]
            return {
                label: dealSummary.district,
                data: dealSummary.history?.map((entry: KaaveeApi.DealSummary) => ({
                    x: new Date(entry.date),
                    y: entry.averageSquarePrice
                })) ?? [],
                borderColor: color,
                backgroundColor: color,
            }
        })
    }
}

export const DealSummaryHistoryPriceChart: React.FC<{
    typeHistory: KaaveeApi.DealSummaryHistory[]
}> = ({
    typeHistory: history
}) => {
        const [data, setData] = React.useState<ChartData<"line", DealChartData[]> | null>(null)

        React.useEffect(() => {
            setData(dealSummaryHistoryToPriceData(history))
        }, [history])
        return (
            <div style={{width: '100%', height: '40vh'}}>
                {data && <Line options={priceHistoryOptions} data={data} />}
            </div>
        )
    }

const countHistoryOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: true,
            text: 'Deal count history',
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
                tooltipFormat: 'DD.MM.YYYY'
            },
            title: {
                display: true,
                text: 'Date'
            }
        },
    }
}

function dealSummaryHistoryToCountData(dealSummary: KaaveeApi.DealSummaryHistory[]): ChartData<"line", DealChartData[]> {
    return {
        datasets: dealSummary.map(function (dealSummary: KaaveeApi.DealSummaryHistory, index) {
            const color = ColorPalette[index]
            return {
                label: dealSummary.district,
                data: dealSummary.history?.map((entry: KaaveeApi.DealSummary) => ({
                    x: new Date(entry.date),
                    y: entry.count
                })) ?? [],
                borderColor: color,
                backgroundColor: color,
            }
        })
    }
}

export const DealSummaryHistoryCountChart: React.FC<{
    typeHistory: KaaveeApi.DealSummaryHistory[]
}> = ({
    typeHistory: history
}) => {
        const [data, setData] = React.useState<ChartData<"line", DealChartData[]> | null>(null)

        React.useEffect(() => {
            setData(dealSummaryHistoryToCountData(history))
        }, [history])
        return (
            <div style={{width: '100%', height: '40vh'}}>
                {data && <Line options={countHistoryOptions} data={data} />}
            </div>
        )
    }
