import moment from 'moment';
import React from 'react';
import { useSearchParams } from "react-router-dom";
import { SelectChangeEvent, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { KaaveeApi } from '../api/KaaveeApi';
import { DealSummaryHistoryCountChart, DealSummaryHistoryPriceChart } from '../components/HistoryChart';
import { DealTypeSelect } from '../components/DealTypeSelect';

interface StatsQueryParams {
    since: string,
    type: KaaveeApi.DealType
}

const Stats: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [items, _setItems] = React.useState<KaaveeApi.DealSummaryHistory[]>([])
    const [error, _setError] = React.useState(false)

    const setResult = (items: KaaveeApi.DealSummaryHistory[]) => {
        const error = items == null
        _setError(error)
        if (!error)
            _setItems(items)
    }

    const queryParams: StatsQueryParams = React.useMemo(() => {
        const since = searchParams.get("since") ?? (() => {
            const backdate = new Date(new Date().getFullYear(), 0, 1)
            return moment(backdate).format("YYYY-MM-DD")
        })()

        const typeParam = searchParams.get("type")
        let type: KaaveeApi.DealType
        if (typeParam && typeParam in KaaveeApi.DealType)
            type = KaaveeApi.DealType[typeParam as keyof typeof KaaveeApi.DealType]
        else
            type = KaaveeApi.DealType.SALE_APARTMENT
        return {
            since: since,
            type: type
        }
    }, [searchParams])

    React.useEffect(() => {
        if (queryParams.since == null || queryParams.type == null) {
            setResult([])
            return
        }

        fetch(`${process.env.REACT_APP_BASE_URL}/summary/${queryParams.type}?since=${queryParams.since}`)
            .then(response => {
                if (!response.ok) {
                    throw response
                }
                return response.json()
            })
            .then(json => setResult(json))
            .catch(error => {
                console.error(error)
                setResult([])
            })
    }, [queryParams])

    const onSinceChange = React.useCallback((value: any) => {
        const date = value as Date

        if (date !== undefined)
            setSearchParams({
                since: moment(date).format("YYYY-MM-DD"),
                type: queryParams.type
            })
    }, [queryParams, setSearchParams])

    const onTypeChange = React.useCallback((event: SelectChangeEvent<KaaveeApi.DealType>) => {
        const type = KaaveeApi.DealType[event.target.value as keyof typeof KaaveeApi.DealType]
        if (type !== undefined)
            setSearchParams({
                since: queryParams.since,
                type: type.toString()
            })
    }, [queryParams, setSearchParams])

    return (
        <div className='StatsPage'>
            <DatePicker inputFormat="DD.MM.YYYY" className='DatePicker' label="Since" onChange={onSinceChange} value={queryParams.since} renderInput={(params) => <TextField error={error} {...params} />} />
            <DealTypeSelect value={queryParams.type} onChange={onTypeChange} />
            {items.length > 0 && <DealSummaryHistoryPriceChart typeHistory={items} />}
            {items.length > 0 && <DealSummaryHistoryCountChart typeHistory={items} />}
        </div>
    );
}

export default Stats
